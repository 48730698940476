





import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "DonationMonthlyStatusTag",
})
export default class DonationMonthlyStatusTag extends Vue {
  @Prop({ required: true })
  value!: boolean;

  @Prop({ required: false })
  size?: string;
}
