















































import { Component, Vue } from "vue-property-decorator";
import {
  AttachmentHostType,
  CycleDonateConfigDto,
  DataDictionaryDtoPagedResultDto,
  FundDonationMonthlyConfigDto,
} from "@/api/appService";
import api from "@/api";
import ExportWord from "@/components/DownloadWord/index.vue";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import EnumTypeTag from "@/components/enumTypeTagFormatter/EnumTypeTag.vue";
import DonationMonthlyStatusTag from "@/views/fund/fundDonationMonthly/components/DonationMonthlyStatusTag.vue";
@Component({
  name: "FundDonationMonthlyDetail",
  components: {
    DonationMonthlyStatusTag,
    EnumTypeTag,
    ExportWord,
    AttachmentsView,
  },
})
export default class FundDonationMonthlyDetail extends Vue {
  dataId?: number;
  detail: CycleDonateConfigDto = {};

  loading = true;

  created() {
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      this.fetchDetail();
    }
  }

  // 获取详情
  async fetchDetail() {
    api.cycleDonateConfig.getDetail({ id: this.dataId }).then((res) => {
      this.detail = { ...res };
      this.loading = false;
    });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
