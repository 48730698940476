





import { Component, Prop, Vue } from "vue-property-decorator";
import api from "@/api/index";
import { SelectListItem } from "@/api/appService";

@Component({
  name: "EnumTypeTag",
})
export default class EnumTypeTag extends Vue {
  @Prop({ required: false, default: "" })
  size!: string;

  @Prop({ required: false, default: "" })
  value!: any;

  @Prop({ required: true })
  typeName!: string;

  @Prop({ required: false, default: false })
  inline!: boolean | string;

  values: SelectListItem[] = [];

  async created() {
    await (this as any).$store
      .dispatch("enumValues/fetch", this.typeName)
      .then((items: SelectListItem[]) => {
        this.values = items;
      });

    console.log(
      "EnumTypeTag inline:",
      this.typeName,
      this.inline,
      typeof this.inline
    );
  }

  get inlineMode() {
    if (typeof this.inline === "string") {
      return this.inline !== "false";
    } else {
      return this.inline;
    }
  }

  getText() {
    if (!this.value) {
      return "";
    } else if (!(this.values && this.values.length)) {
      return "";
    } else {
      const filterResult = this.values.filter(
        (item) => item.value === this.value
      );
      if (filterResult.length) {
        return filterResult[0].text;
      } else {
        return "";
      }
    }
  }
}
